import React, { useRef, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import circlecrown from "../../assets/images/home/circlecrown.svg";
import Countdown from "react-countdown";
import { Canvas } from "@react-three/fiber";
import goldencanonwhite from "../../assets/images/home/goldencanonwhite.svg";
import Model from "../3dmodels/model5";
import logo from "../../assets/images/header/logo.svg";
import { Environment, Float } from "@react-three/drei";
import { Suspense } from "react";
import Button from "../button/Button";
import ButtonExternal from "../button/ButtonExternal";
const MobileMenuStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--mfgreen);
  z-index: 100;
  /* background-image: url(${goldencanonwhite}); */
  background-size: 100% 100%;
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
  transform: translateY(0px);
  transition: transform 0.75s cubic-bezier(0.79, 0.14, 0.15, 0.86);
  &.closed {
    transform: translateY(-100%);
  }

  .header {
    position: absolute;
    top: 32px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    z-index: 1;
    h1 {
      line-height: 0.8;
    }
  }
  .footer {
    position: absolute;
    bottom: 40px;
    padding: 16px;
    display: flex;
    align-items: center;
    z-index: 1;

    left: 0;
    right: 0;
    a {
      margin-right: 18px;
    }
  }
  menu {
    display: block;
    position: relative;
    padding: 0 10px;
    z-index: 200;
    margin-top: 180px;
    ul {
      margin: 0;
      padding: 0;

      li {
        font-size: 1.4em;
        list-style: none;
        text-transform: uppercase;
        line-height: 2;

        a {
          padding: 5px;

          &.active {
            background: var(--mfpink);
          }
        }
      }
    }
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;
const MenuCanvas = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;

  .container {
    position: relative;
    height: 50vmin;
    width: 50vmin;
  }
  .radial {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  .radial_1 {
    background: repeating-radial-gradient(
      transparent,
      var(--mfsand) 1px,
      var(--mfsand) calc(1px),
      transparent calc(1px + 1px),
      transparent calc(1px + 3px)
    );
    -webkit-animation: slide 5s cubic-bezier(0.5, 1, 0.5, 0) alternate infinite;
    animation: slide 5s cubic-bezier(0.5, 1, 0.5, 0) alternate infinite;
  }
  .radial_2 {
    background: repeating-radial-gradient(
      transparent,
      var(--mfsand) 1px,
      var(--mfpink) calc(1px),
      transparent calc(1px + 1px),
      transparent calc(1px + 3px)
    );
  }
  @-webkit-keyframes slide {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  @keyframes slide {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
`;

export default function MobileMenu({ menuOpen, setmenuOpen }) {
  const className = menuOpen ? "open" : "closed";

  const handleMenuClick = (event) => {
    // event.preventDefault();
    // Using the parent component's state to keep track of the menu
    // alert('232');//
    setmenuOpen(!menuOpen);
  };
  return (
    <>
      <MobileMenuStyles className={className}>
        <div className="header">
          <h1>
            Magnetic<br></br> Fields<br></br> Festival
          </h1>
        </div>
        <div className="footer">
          {/* <div
            className="ticketButton"
            onClick={event => handleMenuClick(event)}
          >
            <ButtonExternal
              linkto="https://tickets.magneticfields.in"
              linktext="Tickets"
            >
              Tickets
            </ButtonExternal>
          </div> */}
          {/* <ButtonExternal
            linktext="Festival App"
            linkto="https://onelink.to/y7bj72"
          ></ButtonExternal> */}
        </div>
        <menu>
          <ul>
            <li className="b">
              <Link
                to="/"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                Home
              </Link>
            </li>

            <li className="b">
              <Link
                to="/lineup"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                Lineup
              </Link>
            </li>
            <li className="b">
              <Link
                to="/showcases"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                Showcases
              </Link>
            </li>
            <li className="b">
              <Link
                to="/info"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                Info & FAQs
              </Link>
            </li>
            <li className="b">
              <Link
                to="/news"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                News & Updates
              </Link>
            </li>
            <li className="b">
              <Link
                to="/fieldlines"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                Fieldlines
              </Link>
            </li>
            <li className="b">
              <a
                href="https://shop.magneticfields.in/"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                Merchandise
              </a>
            </li>
            <li className="b">
              <Link
                to="/magnetic-minutes"
                activeClassName="active"
                onClick={(event) => handleMenuClick(event)}
              >
                #MagneticMinutes
              </Link>
            </li>
            {/* <li className="b">
              <a
                href="https://onelink.to/y7bj72"
                activeClassName="active"
                target="_blank"
                onClick={event => handleMenuClick(event)}
              >
                Festival App
              </a>
            </li> */}
          </ul>
        </menu>
        <MenuCanvas>
          {/* <Canvas
            pixelratio={[1, 2]}
            camera={{ position: [0, 0, 20], fov: 50, near: 0.1, far: 50 }}
          >
            <Suspense fallback={null}>
              <Float
                speed={1} // Animation speed, defaults to 1
                rotationIntensity={3} // XYZ rotation intensity, defaults to 1
                floatIntensity={4} // Up/down float intensity, defaults to 1
              >
                <Model />
              </Float>

              <Environment files="/venice_sunset_1k_reduced.hdr" />
            </Suspense>
          </Canvas> */}
          <div class="container">
            <div class="radial radial_1"></div>
            <div class="radial radial_2"></div>
          </div>
        </MenuCanvas>
      </MobileMenuStyles>
    </>
  );
}
