import React, { useState, useEffect } from "react";
import Header from "./Header/Header";
import "normalize.css";
import Footer from "./Footer/Footer";
// import Typography from "../styles/Typography";
// import Banner from "./Header/Banner";
import MobileMenuButton from "./Header/MobileMenuButton";
import MobileMenu from "./MobileMenu/MobileMenu";

export default function Layout({ children, location, path }) {
  // Colour

  const [menuOpen, setmenuOpen] = useState(false);

  const handleMenuClick = (event) => {
    setmenuOpen("closed");
  };

  return (
    <>
      <Header location={location} />
      {/* <MobileMenuButton menuOpen={menuOpen} setmenuOpen={setmenuOpen} /> */}
      {/* <MobileMenu menuOpen={menuOpen} setmenuOpen={setmenuOpen} /> */}
      {children}
      <Footer />
    </>
  );
}
