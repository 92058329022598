exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-lineup-js": () => import("./../../../src/pages/lineup.js" /* webpackChunkName: "component---src-pages-lineup-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-showcases-js": () => import("./../../../src/pages/showcases.js" /* webpackChunkName: "component---src-pages-showcases-js" */),
  "component---src-pages-stages-js": () => import("./../../../src/pages/stages.js" /* webpackChunkName: "component---src-pages-stages-js" */),
  "component---src-templates-artists-js": () => import("./../../../src/templates/artists.js" /* webpackChunkName: "component---src-templates-artists-js" */),
  "component---src-templates-info-js": () => import("./../../../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-showcases-js": () => import("./../../../src/templates/showcases.js" /* webpackChunkName: "component---src-templates-showcases-js" */),
  "component---src-templates-stages-js": () => import("./../../../src/templates/stages.js" /* webpackChunkName: "component---src-templates-stages-js" */)
}

