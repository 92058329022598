import React from "react";
import styled from "styled-components";
import Mailchimp from "react-mailchimp-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faVimeo,
  faYoutube,
  faTwitter,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons";
const NewsletterStyles = styled.div`
  background:var(--mfsand);
  background-color: var(--mfgreen) ;
opacity: 0.8;
/* background-image: radial-gradient(black 0.5px, var(--mfpurple) 0.5px); */
background-size: 10px 10px;
grid-column-start: 1;
  grid-column-end:25;
  @media (min-width: 768px) {

grid-column-start: 1;
grid-column-end: 25;
  }
  @media (min-width: 1420px) {
grid-column-start: 1;
grid-column-end: 25;
  }

  border-right:1px solid black;
  color: black;
  padding: 30px;
  display: flex;
  align-items: center;
 justify-content:flex-start;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: column;
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 400px;
    @media (min-width: 768px) {
      justify-content: center;
      flex-direction: column;
      text-align: center;
    }
    h1 {
      font-family: 'IBM Semi-Bold 600';
      font-size:1.2rem;
      text-transform: uppercase;
      margin: 10px 0 10px 0px;
      @media (min-width: 768px) {
        margin: 0px 0px 0px 0px;
      }
    }
    p{
     text-transform:uppercase
    }
    .icon {
      width: 50px;
    }
  }
  .right {
    max-width: 400px;
    text-align: center;
    button,
    input[type='submit'],
    input[type='reset'] {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      border-radius:8px;
    }
    input {
      padding: 10px 20px;
      border: 1px solid black;
      width: 100%;
      width: 100%;
      /* width: 200px; */
      box-sizing: border-box;
      text-align: center;
      max-width: 400px;
      height:49px;
      @media (min-width: 768px) {
        text-align: center;
      }
      border-radius:8px;
    }
    button {
      padding: 10px 20px;
      background: var(--mfblack);
      color: var(--mfwhite);
      border: 1px solid black);
      width: 100%;
      max-width: 400px;
      border:1px solid black;
      margin-top:12px;
    }
    @media (min-width: 768px) {
      text-align: centers;
    }


    .msg-alert {
      p {
        color: black !important;
      }
    }
  }
  .socials {
       
        margin-top: 32px;
        margin-bottom: 16px;
        @media (min-width: 768px) {
         
          margin-top: 32px;
        margin-bottom: 16px;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            margin-right: 25px;
            padding: 0;
            list-style: none;
            display: inline-block;
            font-size: 1.5em;
            @media (min-width: 768px) {
              font-size: 1.5em;
            }
            a {
              color: black;
            }
          }
        }
      }
`;

export default function Newsletter() {
  const url =
    "https://magneticfields.us3.list-manage.com/subscribe/post?u=6a5696a71fb929a68c951a4a9&amp;id=1fa3f1219f&amp;f_id=0053b8e2f0";

  return (
    <NewsletterStyles>
      <div className="left">
        <h1>STAY CONNECTED</h1>
        <div className="socials">
          <ul>
            <li>
              <a
                href="https://www.facebook.com/magneticfieldsfestival/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/magneticfieldsfestival/"
                target="_blank"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UC-fZp9wjxFL00hhtUDn3e7Q"
                target="_blank"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/magneticfields" target="_blank">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </li>
            <li>
              <a
                href="https://soundcloud.com/magneticfieldsfestival"
                target="_blank"
              >
                <FontAwesomeIcon icon={faSoundcloud} />
              </a>
            </li>
          </ul>
        </div>
        <p className="sb">
          Join our Newsletter to stay <br></br>updated on major announcements.
        </p>
      </div>
      <div className="right">
        <Mailchimp
          action="https://magneticfields.us3.list-manage.com/subscribe/post?u=6a5696a71fb929a68c951a4a9&amp;id=1fa3f1219f&amp;f_id=0053b8e2f0"
          fields={[
            {
              name: "EMAIL",
              placeholder: "FIELDER@YOUREMAIL.COM",
              type: "email",
              required: true,
            },
          ]}
          messages={{
            sending: "Sending...",
            success: "Thank you for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "Please enter an e-mail address.",
            duplicate: "E-mail already subscribed.",
            button: "SUBSCRIBE",
          }}
          className="mailchimpform"
        />
      </div>
    </NewsletterStyles>
  );
}
