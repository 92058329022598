import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
 :root {
	--red: #FF4949;
	--black: #2E2E2E;
	--yellow: #ffc600;
	--white: #fff;
	--grey: #efefef;
	--mfwhite: #FCF8E6;
	--mfyellow: #E8DCC4;
	--mfpink: #EBDCE2;
	--mfgreen: #ACAC9A;
	--mfblue: #AFBCC6;
	--mfblack: #282828;
	--borderblack: 1px solid var(--mfblack);
	--mfpurple: #7F667C;
	--mfsand: #CDC5BA;
	--mfbrown: #B2958E;
	--mfgrey: #D4CDB5;
	--mflightgrey: #949494;
	--mforange: #C46136;
	--mfdarkblue:#091A3B;
	
}

html {
	background: var(--mfgrey);
	
	font-size: 16px;
	line-height:1.7
}

body {
	font-size: 1rem;
	font-family: 'IBM Regular', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	transition: opacity .3s ease-in;
	width: 100vw;

	
	.cutoff {
		
		/* min-height:100vh;
		margin-top: 80px;
		@media (min-width: 768px) {
  		  margin-top: 	44px;
 		 }	 */
	}
}

a {
	text-decoration: none
}


fieldset {
	border-color: rgba(0, 0, 0, 0.1);
	border-width: 1px;
}

button {}

textarea:focus,
input:focus {
	outline: none;
}

hr {
	border: 0;
	height: 8px;
	background-size: 1500px;
}

img {
	max-width: 100%;
}

.wrapper-full {
	margin-left: auto;
	margin-right: auto;
}

.grid16 {
	display: grid;
	grid-template-columns: repeat(16, [col-start] 1fr);
}
.grid24 {
	display: grid;
	grid-template-columns: repeat(24, [col-start] 1fr);
}
`;

export default GlobalStyles;
